@import "../../styles/helpers";

.magnifier {
  border-radius: 64px;
  background-color: $gray;
  height: 504px;
  @include dark {
    background-color: $dark; }
  @include m {
    display: none; }
  > div {
    background-color: $gray;
    @include dark {
      background-color: $dark; } } }

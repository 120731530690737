@import "../../styles/helpers";

.head {
	display: flex;
	align-items: flex-end;
	margin-bottom: 72px;
	@include x {
		margin-bottom: 64px; }
	@include m {
		margin-bottom: 56px; }
	@include a {
		display: block; } }

.box {
	margin-right: auto;
	@include a {
		margin: 0 0 24px; } }

.row {
	display: flex;
	@include x {
		display: block; } }

.col {
	&:first-child {
		flex-grow: 1;
		padding-right: 48px;
		@include x {
			margin-bottom: 48px;
			padding: 0; }
		@include m {
			margin-bottom: 40px; } }
	&:nth-child(2) {
		flex-shrink: 0;
		width: 416px;
		@include x {
			width: 100%; } } }

@import "../../../styles/helpers";

.details {
  @include d {
    text-align: center; } }

.center {
  max-width: 1176px; }

.row {
  display: flex;
  @include d {
    display: block; } }

.col {
  &:first-child {
    flex-grow: 1;
    padding-right: 72px;
    @include x {
      padding-right: 56px; }
    @include d {
      margin-bottom: 64px;
      padding: 0; }
    @include m {
      margin-bottom: 56px; } }
  &:nth-child(2) {
    flex-shrink: 0;
    width: 496px;
    padding-top: 32px;
    @include d {
      width: 100%;
      padding: 0; } } }

.item {
  display: flex;
  @include d {
    display: block; }
  @include nl {
    margin-bottom: 57px;
    @include d {
      margin-bottom: 64px; }
    @include m {
      margin-bottom: 56px; } } }

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  margin-right: 32px;
  border-radius: 50%;
  background: $gray;
  @include dark {
    background: $dark;
    path {
      stroke: $wh; } }
  path {
    stroke: $bl-dark; }
  @include d {
    margin: 0 auto 32px; } }

.category {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: (40/24); }

.text {
  font-size: 20px;
  line-height: 1.6; }

@import "../../styles/helpers";

.counter {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  width: 176px;
  @include m {
    width: 136px; }
  input {
    width: 100%;
    height: 64px;
    padding: 0 50px;
    border-radius: 32px;
    border: 2px solid $gray;
    text-align: center;
    @include dark {
      border-color: $dark;
      background: $bg-dark;
      color: $dark-wh; }
    @include f;
    font-size: 24px;
    font-weight: 700;
    color: $bl;
    @include m {
      height: 48px;
      padding: 0 40px;
      border-radius: 24px;
      font-size: 20px; } }
  .button {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 50px;
    font-size: 0;
    transition: all .25s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include m {
      width: 40px; }
    .icon {
      font-size: 15px;
      fill: $bl-dark;
      transition: fill .25s;
      @include dark {
        fill: $wh; }
      @include m {
        font-size: 13px; } }

    &:hover .icon {
      fill: rgba($bl-dark, .7);
      @include dark {
        fill: rgba($wh, .75); } } }
  .plus {
    right: 0;
    padding-right: 10px; }
  .minus {
    left: 0;
    padding-left: 10px; } }

@import "../../styles/helpers";

.menu {
  position: absolute;
  bottom: 48px;
  display: flex;
  align-items: center;
  @include t {
    bottom: 36px; }
  @include m {
    left: 32px;
    bottom: 32px;
    z-index: 6; }
  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    font-size: 0;
    @include nl {
      margin-right: 24px; }
    .icon {
      @include dark {
        fill: $wh; }
      &:hover {
        fill: rgba($bl, .75);
        @include dark {
          fill: rgba($wh, .75); } } } } }

.footer {
  margin-bottom: 48px;
  display: flex;
  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $gray;
    transition: background .25s;
    @include dark {
      background: $dark;
      &:hover {
        background: rgba($dark, .75); }
      @include m {
        background: none !important; } }
    &:hover {
      background: rgba($gray, .75); }
    @include m {
      width: 24px;
      height: 24px;
      background: none !important; }
    @include nl {
      margin-right: 16px;
      @include m {
        margin-right: 24px; } }
    .icon {
      @include dark {
        fill: $wh; } } } }




.icon {
  font-size: 20px;
  fill: $bl-dark;
  transition: fill .25s; }

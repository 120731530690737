@import "../../styles/helpers";

.form {
  max-width: 496px;
  margin: 0 auto;
  @include t {
    max-width: 100%; } }

.field {
  @include nl {
    margin-bottom: 48px;
    @include m {
      margin-bottom: 40px; } } }

.checkbox {
  margin-bottom: 46px;
  @include m {
    margin-bottom: 38px; } }

.row {
  display: flex;
  align-items: center;
  margin: 0 -12px;
  text-align: center;
  @include a {
    display: block;
    margin: 0; } }

.col {
  flex: 0 0 calc(50% - 24px);
  width: calc(50% - 24px);
  margin: 0 12px;
  @include m {
    width: 100%;
    margin: 0; }
  @include nl {
    @include m {
      margin-bottom: 24px; } } }
.button {
  padding: 0 16px; }

.buttons {
  margin-bottom: 24px; }

.link {
  text-decoration: underline;
  font-size: 20px;
  color: $bl;
  transition: color .25s;
  @include dark {
    color: $dark-wh;
    &:hover {
      color: $green; } }
  @include m {
    font-size: 16px; }
  &:hover {
    color: $green; } }

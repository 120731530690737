@import "../../styles/helpers";

.bg {
  width: 100%;
  height: 600px;
  border-radius: 64px;
  @include cover;
  @include d {
    height: 504px;
    border-radius: 56px; }
  @include t {
    height: 400px;
    border-radius: 40px; }
  @include m {
    height: 311px;
    border-radius: 48px; } }

.center {
  max-width: 1152px; }

.row {
  display: flex;
  @include d {
    display: block; } }

.col {
  &:first-child {
    flex-shrink: 0;
    width: 456px;
    @include d {
      width: 100%;
      margin-bottom: 64px;
      text-align: center; }
    @include m {
      margin-bottom: 56px; } }
  &:nth-child(2) {
    flex-grow: 1;
    padding: 30px 0 0 120px;
    @include d {
      padding: 0; } } }

.title {
  margin-bottom: 96px;
  @include d {
    margin-bottom: 64px; }
  @include t {
    font-size: 32px;
    line-height: 1.5; }
  @include m {
    margin-bottom: 56px; } }

.photo {
  width: 100%;
  height: 560px;
  border-radius: 56px;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
  @include d {
    height: 504px;
    background-position: 50% 50%; }
  @include t {
    height: 400px;
    border-radius: 40px; }
  @include m {
    height: 311px;
    border-radius: 48px; } }

.item {
  @include nl {
    margin-bottom: 48px;
    @include d {
      margin-bottom: 64px; }
    @include m {
      margin-bottom: 56px; } } }

.category {
  position: relative;
  margin-bottom: 32px;
  padding-left: 32px;
  font-size: 24px;
  line-height: (40/24);
  font-weight: 600;
  color: $green;
  @include t {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 1.6; }
  &:before {
    content: "";
    position: absolute;
    top: 16px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $green;
    @include t {
      top: 12px; } } }

.text {
  padding-left: 32px;
  font-size: 24px;
  line-height: 2;
  @include t {
    font-size: 20px; } }

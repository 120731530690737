@import "../../styles/helpers";

.Newsletter {
  .container {
    padding: 96px 48px;
    border-radius: 56px;
    background: $gray;
    @include d {
      padding: 64px 48px; }
    @include t {
      padding: 0;
      border-radius: 0;
      background: none; }
    @include dark {
      background: $dark;
      @include t {
        background: none; } } } }

.stage {
  text-align: center; }

.title {
  text-align: center;
  @include t {
    font-size: 32px;
    line-height: 1.5; } }

.form {
  display: flex;
  width: 100%;
  max-width: 635px;
  margin: 0 auto;
  @include a {
    display: block; }
  .field {
    flex-grow: 1;
    @include a {
      margin-bottom: 24px; }
    input {
      border-color: transparent;
      font-weight: 400;
      @include t {
        background: $gray; }
      @include dark {
        background: #2C2C37;
        border-color: #2C2C37;
        color: $dark-wh;
        @include placeholder {
          color: rgba($dark-wh, .64); } } } } }

.buttons {
  flex-shrink: 0;
  width: 186px;
  padding-left: 24px;
  @include a {
    width: 114px;
    padding: 0; } }



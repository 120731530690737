@import "../../styles/helpers";

.head {
  position: relative;
  padding-right: 64px;
  font-size: 24px;
  font-weight: 600;
  line-height: (40/24);
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  cursor: pointer;
  transition: opacity .25s;
  @include t {
    padding-right: 88px;
    font-size: 20px;
    line-height: 1.6; }
  @include m {
    padding-right: 72px; }
  &:hover {
    opacity: .85; } }

.arrow {
  position: absolute;
  top: -2px;
  right: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid $gray;
  @include dark {
    border-color: $dark; }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 14px;
    height: 8px;
    background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='black'/%3E%3C/svg%3E%0A") no-repeat 50% 50% / auto 100%;
    transition: transform .25s;
    @include dark {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='white'/%3E%3C/svg%3E%0A"); } } }

.arrow.active:before {
  transform: translate(-50%,-50%) rotate(180deg); }

.body {
  display: none;
  padding: 16px 64px 0 0;
  font-size: 18px;
  line-height: (32/18);
  @include d {
    padding-right: 134px; }
  @include t {
    padding-right: 88px;
    font-size: 16px;
    line-height: 2; }
  @include m {
    padding-right: 0; }
  a {
    text-decoration: underline;
    font-weight: 600;
    color: $bl;
    @include dark {
      color: $dark-wh; }
    &:hover {
      text-decoration: none; } } }

html {
  &.no-scroll {
    overflow: hidden; } }

body {
  min-width: 375px;
  min-height: 100vh;
  @include f;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $bl;
  &.no-scroll {
    overflow: hidden; }
  @include dark-body {
    background: $bg-dark;
    color: $dark-wh; } }

button,
input,
textarea,
select {
  @include f; }

.section {
  margin-bottom: 144px;
  @include x {
    margin-bottom: 112px; }
  @include m {
    margin-bottom: 104px; } }

.wide {
  width: 100%; }

.center {
  max-width: 1344px;
  margin: 0 auto;
  padding: 0 48px;
  @include t {
    padding: 0 40px; }
  @include m {
    padding: 0 32px; } }

.title {
  font-size: 40px;
  line-height: (56/40);
  font-weight: 700;
  @include m {
    font-size: 32px;
    line-height: 1.5; }
  &_mb-md {
    margin-bottom: 48px;
    @include m {
      margin-bottom: 24px; } }
  &_mb-lg {
    margin-bottom: 72px;
    @include x {
      margin-bottom: 64px; }
    @include m {
      margin-bottom: 56px; } }
  br {
    @include m {
      display: none; } } }

.stage {
  margin-bottom: 8px;
  line-height: 1.5;
  font-weight: 600;
  font-style: italic;
  color: $blue; }

.icon-header {
  font-size: 21px;
  fill: $bl-dark;
  transition: fill .25s;
  @include dark-common {
    fill: $wh;
    &:hover {
      fill: rgba($wh, .75); } }
  @include m {
    font-size: 24px; }
  &:hover {
    fill: rgba($bl-dark, .75); } }

.yellow {
  background: rgba($yellow, .1);
  color: $yellow; }

.blue {
  background: rgba($blue, .1);
  color: $blue; }

.pink {
  background: rgba($pink, .1);
  color: $pink; }

.green {
  background: rgba($green, .1);
  color: $green; }






@import "../../styles/helpers";

.details {
  @include nl {
    margin-bottom: 144px;
    @include x {
      margin-bottom: 112px; }
    @include m {
      margin-bottom: 104px; } } }

.details .title {
  margin-bottom: 144px;
  @include x {
    margin-bottom: 112px; }
  @include m {
    margin-bottom: 104px; } }

.list {
  display: flex;
  flex-wrap: wrap;
  margin: -72px -48px 0;
  @include d {
    display: block;
    margin: 0; } }

.item {
  flex: 0 0 calc(50% - 96px);
  width: calc(50% - 96px);
  margin: 72px 48px 0;
  @include d {
    width: 100%;
    margin: 0; }
  @include nl {
    @include d {
      margin-bottom: 64px; }
    @include m {
      margin-bottom: 56px; } } }

.category {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: (40/24); }

.text {
  font-size: 18px;
  line-height: (32/18);
  a {
    text-decoration: underline;
    font-weight: 600;
    color: $bl;
    @include dark {
      color: $dark-wh; }
    &:hover {
      text-decoration: none; } } }

.container .center {
  max-width: 1176px; }

.row {
  display: flex;
  margin: 0 -48px;
  @include d {
    display: block;
    margin: 0; } }

.wrap, .form {
  flex: 0 0 calc(50% - 96px);
  width: calc(50% - 96px);
  margin: 0 48px;
  @include d {
    width: 100%;
    margin: 0; } }

.wrap {
  @include d {
    max-width: 440px;
    margin-bottom: 64px; }
  @include t {
    max-width: 400px; }
  @include m {
    margin-bottom: 56px; } }

.wrap .title {
  @include t {
    font-size: 32px;
    line-height: 1.5; } }

.form {
  padding-top: 40px;
  @include d {
    padding: 0; } }

.field {
  @include nl {
    margin-bottom: 48px;
    @include m {
      margin-bottom: 40px; } } }

.button {
  min-width: auto; }

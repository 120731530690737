@import "../../styles/helpers";

.row {
  display: flex;
  align-items: flex-start;
  @include d {
    display: block; } }

.col {
  &:first-child {
    flex-shrink: 0;
    width: 440px;
    padding: 54px 56px;
    border-radius: 48px;
    border: 2px solid $gray;
    @include dark {
      border-color: $dark; }
    @include d {
      width: 100%;
      margin-bottom: 64px;
      padding: 0;
      border-radius: 0;
      border: none; }
    @include m {
      margin-bottom: 56px; } }
  &:nth-child(2) {
    flex-grow: 1;
    padding-left: 96px;
    @include x {
      padding-left: 64px; }
    @include d {
      padding-left: 0; } } }

.filters {
  margin-bottom: 0;
  @include x {
    margin-bottom: 0; }
  @include m {
    margin-bottom: 0; } }

.box {
  @include rmin(1200) {
    display: block !important; }
  @include d {
    display: none;
    padding-top: 40px; }
  .field {
    @include nl {
      margin-bottom: 48px;
      @include m {
        margin-bottom: 40px; } } } }

.title {
  margin-bottom: 48px;
  font-size: 32px;
  line-height: 1.5;
  font-weight: 600;
  @include d {
    display: none; } }

.fieldset {
  margin-bottom: 80px;
  @include d {
    margin-bottom: 64px; }
  @include m {
    margin-bottom: 56px; } }

.buttons .button {
  @include nl {
    margin-bottom: 24px;
    @include m {
      margin-bottom: 16px; } } }

.open {
  @include d {
    display: inline-flex; }
  @include m {
    margin-top: 0; } }

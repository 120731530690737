@import "../../styles/helpers";

.footer {
  padding-bottom: 72px;
  @include x {
    padding-bottom: 64px; }
  @include m {
    padding-bottom: 56px; }
  .center {
    max-width: 1244px; } }

.row {
  display: flex;
  padding-right: 15px;
  @include x {
    padding: 0; }
  @include t {
    flex-wrap: wrap;
    margin-top: -64px;
    padding-right: 30px; }
  @include m {
    margin-top: -56px;
    padding: 0; }
  .col {
    @include t {
      flex: 0 0 50%;
      width: 50%;
      margin-top: 64px;
      padding-right: 32px; }
    @include m {
      margin-top: 58px;
      padding-right: 24px; }
    @include a {
      width: auto;
      flex: 0 0 auto;
      padding: 0; }
    &:nth-child(2n+1) {
      @include a {
        margin-right: auto !important;
        padding-right: 20px; } }
    &:nth-child(2n) {
      @include a {
        width: 113px; } }
    @include nl {
      margin-right: auto;
      @include t {
        margin-right: 0; } } } }

.logo {
  display: inline-block;
  margin-bottom: 25px;
  font-size: 0;
  @include m {
    margin-bottom: 15px; }
  .logo_desktop, .logo_mobile {
    width: 117px;
    @include m {
      width: 74px;
      margin-top: -2px; } }
  .logo_desktop {
    @include m {
      display: none; } }
  .logo_mobile {
    display: none;
    @include m {
      display: inline-block; } } }

.copyright {
  margin-bottom: 32px;
  line-height: 1.5;
  @include t {
    margin-bottom: 24px; }
  @include m {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: (24/14); }
  @include a {
    max-width: 135px; } }


.category {
  margin-bottom: 26px;
  font-size: 24px;
  line-height: (32/24);
  font-weight: 700;
  @include m {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 1.6; } }

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .link {
    line-height: 1.5;
    color: $bl;
    transition: color .25s;
    @include dark {
      color: $dark-wh;
      &:hover {
        color: rgba($dark-wh, .75); } }
    @include m {
      font-size: 14px; }
    &:hover {
      color: rgba($bl, .75); }
    @include nl {
      margin-bottom: 16px;
      @include m {
        margin-bottom: 19px; } } } }

@import "../../../styles/helpers";

.item {
	padding: 54px 56px;
	border-radius: 48px;
	border: 2px solid $gray;
	@include dark {
		border-color: $dark; }
	@include t {
		border-radius: 40px; }
	@include m {
		padding: 24px;
		border-radius: 32px; }
	@include nl {
		margin-bottom: 48px;
		@include m {
			margin-bottom: 40px; } }
	.title {
		margin-bottom: 24px;
		font-size: 32px;
		line-height: 1.5;
		font-weight: 600;
		@include m {
			margin-bottom: 16px;
			font-size: 20px;
			line-height: 1.6; } }
	.text {
		margin-bottom: 48px;
		font-size: 20px;
		line-height: 1.6;
		@include m {
			margin-bottom: 40px;
			font-size: 16px;
			line-height: 2; } }
	.button {
		min-width: auto; } }

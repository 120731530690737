@import "../../styles/helpers";

.section {
  @include nl {
    margin-bottom: 144px;
    @include x {
      margin-bottom: 112px; }
    @include d {
      margin-bottom: 64px; } } }

.category {
  margin-bottom: 48px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5;
  @include t {
    margin-bottom: 40px;
    font-size: 24px;
    line-height: (40/24); }
  @include m {
    line-height: (32/24); } }

.list {
  display: flex;
  flex-wrap: wrap;
  margin: -72px -48px 0;
  @include x {
    margin-top: -64px; }
  @include d {
    display: block;
    margin: 0; } }

.item {
  flex: 0 0 calc(50% - 96px);
  width: calc(50% - 96px);
  margin: 72px 48px 0;
  @include x {
    margin-top: 64px; }
  @include d {
    width: 100%;
    margin: 0; }
  @include nl {
    @include d {
      margin-bottom: 48px; }
    @include t {
      margin-bottom: 40px; } } }


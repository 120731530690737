.icon-arrow-next {
  width: 0.57em;
  height: 1em;
}
.icon-arrow-prev {
  width: 0.57em;
  height: 1em;
}
.icon-cart {
  width: 1.05em;
  height: 1em;
}
.icon-close {
  width: 1em;
  height: 1em;
}
.icon-facebook {
  width: 0.59em;
  height: 1em;
  font-size: 22px !important;
}
.icon-heart {
  width: 1.14em;
  height: 1em;
}
.icon-instagram {
  width: 1em;
  height: 1em;
}
.icon-magnifier {
  width: 1em;
  height: 1em;
}
.icon-search {
  width: 0.96em;
  height: 1em;
}
.icon-twitter {
  width: 1.22em;
  height: 1em;
  font-size: 18px !important;
}
.icon-user {
  width: 0.95em;
  height: 1em;
}

@import "../../../styles/helpers";

.title {
  @include t {
    font-size: 32px;
    line-height: 1.5; } }

.title, .stage {
  @include m {
    text-align: center; } }

.list {
  display: flex;
  flex-wrap: wrap;
  margin: -72px -24px 0;
  @include x {
    margin: -64px -28px 0; }
  @include t {
    margin: -64px -24px 0; }
  @include a {
    display: block;
    margin: 0; } }

.product {
  flex: 0 0 calc(25% - 48px);
  width: calc(25% - 48px);
  margin: 72px 24px 0;
  position: relative;
  color: $bl;
  @include dark {
    color: $dark-wh; }
  &:hover .button {
    visibility: visible;
    opacity: 1; }
  @include x {
    flex: 0 0 calc(33.333% - 56px);
    width: calc(33.333% - 56px);
    margin: 64px 28px 0; }
  @include t {
    flex: 0 0 calc(50% - 48px);
    width: calc(50% - 48px);
    margin: 64px 24px 0; }
  @include a {
    width: 100%;
    margin: 0; }
  @include nl {
    @include a {
      margin-bottom: 56px; } } }

.buttons {
  margin-top: 72px;
  text-align: center;
  @include x {
    margin-top: 64px; }
  @include m {
    margin-top: 56px; } }



@import "../../styles/helpers";

.form {
  max-width: 496px;
  margin: 0 auto;
  @include t {
    max-width: 100%; } }

.wrap {
  position: relative;
  margin-bottom: 76px;
  @include m {
    margin-bottom: 58px; } }

.field {
  @include nl {
    margin-bottom: 48px;
    @include m {
      margin-bottom: 40px; } } }

.checkbox {
  margin-bottom: 46px;
  @include m {
    margin-bottom: 38px; } }

.row {
  display: flex;
  align-items: center;
  margin: 0 -12px;
  text-align: center;
  @include a {
    display: block;
    margin: 0; } }

.col {
  flex: 0 0 calc(50% - 24px);
  width: calc(50% - 24px);
  margin: 0 12px;
  @include m {
    width: 100%;
    margin: 0; }
  @include nl {
    @include m {
      margin-bottom: 24px; } } }
.button {
  padding: 0 16px; }

.buttons {
  margin-bottom: 24px; }

.link {
  text-decoration: underline;
  font-size: 20px;
  color: $bl;
  transition: color .25s;
  @include dark {
    color: $dark-wh;
    &:hover {
      color: $green; } }
  @include m {
    font-size: 16px; }
  &:hover {
    color: $green; } }

.status {
  position: absolute;
  top: calc(100% + 24px);
  left: 0;
  right: 0;
  width: 100%;
  height: 4px;
  @include m {
    top: calc(100% + 16px);
    height: 2px; }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0;
    border-radius: 2px; }
  &:before {
    background: $gray;
    @include dark {
      background: $dark; } }
  &:after {
    z-index: 2;
    background: $green; }
  &.success {
    &:before {
      animation: status .7s forwards; } }
  &.reliable {
    &:after {
      animation: status .7s forwards; } } }

@keyframes status {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

@import "../../../styles/helpers";

.item {
	display: flex;
	padding: 54px 56px;
	border-radius: 48px;
	border: 2px solid $gray;
	@include dark {
		border-color: $dark; }
	@include x {
		position: relative;
		padding-right: 150px; }
	@include t {
		padding-right: 56px; }
	@include m {
		display: block;
		padding: 24px;
		border-radius: 32px;
		text-align: center; }
	@include nl {
		margin-bottom: 48px;
		@include m {
			margin-bottom: 40px; } } }

.preview {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 192px;
	height: 192px;
	margin-right: 56px;
	border-radius: 32px;
	background: $gray;
	font-size: 0;
	@include dark {
		background: $dark; }
	@include m {
		width: 96px;
		height: 96px;
		margin: 0 auto 24px;
		border-radius: 16px; }
	img {
		max-width: 100%;
		max-height: 100%; } }

.details {
	flex-grow: 1; }

.product {
	display: block;
	margin-bottom: 16px;
	font-size: 32px;
	line-height: 1.5;
	font-weight: 600;
	color: $bl;
	@include dark {
		color: $dark-wh; }
	@include m {
		font-size: 20px;
		line-height: 1.6; } }

.price {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	line-height: (40/24);
	font-weight: 600;
	@include m {
		margin-bottom: 40px;
		justify-content: center;
		line-height: 1.5; } }

.old {
	margin-right: 8px;
	font-size: 20px;
	text-decoration: line-through;
	color: rgba($bl, .24);
	@include dark {
		color: rgba($dark-wh, .24); }
	@include m {
		font-size: 14px; } }

.actual {
	font-size: 24px;
	@include m {
		font-size: 16px; } }

.control {
	display: flex;
	align-items: center;
	@include m {
		justify-content: center; } }

.counter {
	margin-right: 24px; }

.remove {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 64px;
	height: 64px;
	border: 2px solid $gray;
	border-radius: 50%;
	font-size: 0;
	transition: border-color .25s;
	@include dark {
		border-color: $dark; }
	@include x {
		position: absolute;
		top: 120px;
		right: 56px; }
	@include t {
		position: static; }
	@include m {
		width: 48px;
		height: 48px; }
	.icon {
		font-size: 20px;
		fill: $bl-dark;
		@include dark {
			fill: $wh; }
		@include m {
			font-size: 16px; } }
	&:hover {
		border-color: $bl-dark;
		@include dark {
			border-color: $dark-wh; } } }


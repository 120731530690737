@import "../../../styles/helpers";

.title {
  @include t {
    font-size: 32px;
    line-height: 1.5; } }

.container {
  margin: 0 -24px;
  @include x {
    margin: 0 -28px; }
  @include t {
    margin: 0 -24px; } }

.list {
  visibility: hidden;
  @include m {
    padding-bottom: 88px; } }

.slide {
  padding: 0 24px;
  @include d {
    padding: 0 28px; }
  @include t {
    padding: 0 24px; } }

.product {
  position: relative; }

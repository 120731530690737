@import "../../../styles/helpers";

.receipt {
  padding: 54px 56px;
  border-radius: 48px;
  border: 2px solid $gray;
  @include dark {
    border-color: $dark; }
  @include x {
    padding-right: 168px; }
  @include t {
    padding-right: 56px; }
  @include m {
    padding: 24px;
    border-radius: 32px; } }

.category {
  margin-bottom: 48px;
  font-size: 32px;
  font-weight: 600;
  @include m {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 1.6; } }

.wrap {
  margin-bottom: 80px;
  @include x {
    display: flex;
    flex-wrap: wrap;
    margin: -48px -48px 48px; }
  @include t {
    display: block;
    margin: 0 0 80px; }
  @include m {
    margin-bottom: 56px; } }

.line {
  display: flex;
  @include x {
    flex: 0 0 calc(50% - 96px);
    width: calc(50% - 96px);
    margin: 48px 48px 0; }
  @include t {
    width: 100%;
    margin: 0; }
  @include nl {
    margin-bottom: 48px;
    @include x {
      margin-bottom: 0; }
    @include t {
      margin-bottom: 48px; }
    @include m {
      margin-bottom: 40px; } } }

.line, .text {
  font-size: 24px;
  line-height: (40/24);
  @include m {
    font-size: 16px;
    line-height: 1.5; }
  &:first-child {
    margin-right: auto; } }

.total .text {
  font-weight: 600; }

.receipt .button {
  @include x {
    min-width: auto; }
  @include t {
    min-width: 100%; } }

@import "../../../styles/helpers";

.main {
  .container {
    position: relative; } }

.details {
  position: absolute;
  top: 50%;
  left: 96px;
  z-index: 2;
  transform: translateY(-50%);
  max-width: 500px;
  @include x {
    left: 48px;
    max-width: 370px; }
  @include t {
    max-width: 100%;
    position: static;
    margin-bottom: 48px;
    transform: translateY(0); }
  @include m {
    margin-bottom: 40px; } }

.title {
  margin-bottom: 40px;
  @include t {
    font-size: 40px; }
  @include m {
    margin-bottom: 24px;
    font-size: 32px; } }

.preview {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 24px 24px 0;
  height: 600px;
  background: $gray;
  border-radius: 64px;
  overflow: hidden;
  font-size: 0;
  @include dark {
    background: $dark; }
  @include d {
    height: 504px;
    padding: 48px 16px 24px; }
  @include t {
    display: block;
    height: 400px;
    padding: 16px 40px 0;
    border-radius: 40px;
    text-align: center; }
  @include m {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 311px;
    padding: 10px 8px 0;
    border-radius: 48px; } }

.pic {
  max-width: 100%;
  max-height: 100%; }

@import "../../../styles/helpers";

.blog {
  .center {
    @include m {
      max-width: 375px; } } }

.stage, .title {
  @include t {
    text-align: center; } }

.title {
  @include t {
    font-size: 32px;
    line-height: 1.5; } }

.list {
  display: flex;
  flex-wrap: wrap;
  margin: -72px -24px 0;
  @include x {
    margin: -64px -28px 0; }
  @include t {
    margin: -64px -28px 0; }
  @include m {
    display: block;
    width: calc(100% + 56px);
    margin: 0 -28px;
    padding-bottom: 88px;
    visibility: hidden; } }

.item {
  position: relative;
  margin: 72px 24px 0;
  color: $bl;
  @include x {
    margin: 64px 28px 0; }
  @include t {
    margin: 64px 24px 0; }
  @include m {
    display: block;
    margin: 0; }
  @include dark {
    color: $dark-wh; }
  &:hover {
    .info {
      color: rgba($bl, .75);
      @include dark {
        color: rgba($dark-wh, .8); } } } }

.w33 {
  flex: 0 0 calc(33.333% - 48px);
  width: calc(33.333% - 48px);
  @include x {
    flex: 0 0 calc(33.333% - 56px);
    width: calc(33.333% - 56px); }
  @include t {
    flex: 0 0 calc(50% - 48px);
    width: calc(50% - 48px); }
  @include m {
    width: 100%; } }

.w50 {
  flex: 0 0 calc(50% - 48px);
  width: calc(50% - 48px);
  @include x {
    flex: 0 0 calc(50% - 56px);
    width: calc(50% - 56px); }
  @include t {
    flex: 0 0 calc(100% - 48px);
    width: calc(100% - 48px); }
  @include m {
    width: 100%; } }

.w66 {
  flex: 0 0 calc(66.666% - 48px);
  width: calc(66.666% - 48px);
  @include x {
    flex: 0 0 calc(66.666% - 56px);
    width: calc(66.666% - 56px); }
  @include t {
    flex: 0 0 calc(100% - 48px);
    width: calc(100% - 48px); }
  @include m {
    width: 100%; } }

.preview {
  height: 384px;
  margin-bottom: 40px;
  border-radius: 56px;
  width: 100%;
  @include cover;
  @include x {
    height: 330px;
    margin-bottom: 32px;
    border-radius: 40px; }
  @include d {
    height: 272px; }
  @include t {
    height: 320px;
    border-radius: 48px; }
  @include m {
    height: auto;
    padding-bottom: 100%; } }

.status {
  position: absolute;
  top: 32px;
  right: -32px;
  z-index: 2;
  min-width: 116px;
  padding: 0 22px;
  text-align: center;
  border-radius: 24px;
  background: $red;
  border: 2px solid $wh;
  line-height: 44px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  color: $wh;
  @include dark {
    border-color: $bg-dark; }
  @include x {
    top: 24px;
    right: -24px;
    min-width: 92px;
    padding: 0 14px;
    border-radius: 20px;
    font-size: 14px;
    line-height: 36px; } }

.recent {
  background: $green; }

.popular {
  background: $red; }

.info {
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  transition: color .25s;
  @include x {
    margin-bottom: 16px;
    font-size: 28px; }
  @include d {
    font-size: 24px;
    line-height: (40/24); } }

.category {
  display: inline-block;
  padding: 0 24px;
  border-radius: 24px;
  line-height: 48px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  @include d {
    padding: 0 16px;
    font-size: 14px;
    line-height: 40px; } }

.buttons {
  margin-top: 72px;
  text-align: center;
  @include x {
    margin-top: 64px; }
  @include m {
    margin-top: 56px; } }

@import "../../../styles/helpers";

.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 170px 0 90px;
  background: $wh;
  visibility: hidden;
  opacity: 0;
  transition: visible .25s, opacity .25s, transform .25s;
  @include dark {
    background: $bg-dark; }
  @include x {
    padding-top: 155px; }
  @include t {
    padding: 145px 0 80px; }
  @include m {
    padding: 110px 0 70px; }
  @include a {
    padding-top: 126px; } }

.menu_center {
  width: 100%;
  height: 100%; }

.visible_menu {
  visibility: visible;
  opacity: 1; }

.container {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @include m {
    margin-bottom: 24px;
    overflow: visible;
    height: auto; }
  &::-webkit-scrollbar {
    width: 5px; }
  &::-webkit-scrollbar-track-piece {
    background-color: $gray;
    border-radius: 0; }
  &::-webkit-scrollbar-thumb:vertical {
    width: 5px;
    background-color: rgba($bl, .1);
    border-radius: 0; }
  &::-webkit-scrollbar-thumb:horizontal,
  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgba($bl, .1);
    border-radius: 0; } }

.menu_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform .25s;
  @include m {
    flex-direction: row; }
  @include a {
    flex-direction: column; } }

.item {
  position: relative;
  font-size: 24px;
  line-height: 40px;
  color: $bl;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  @include dark {
    color: $dark-wh;
    &:hover {
      color: $green;
      @include t {
        color: $wh; } } }
  @include m {
    position: static; }
  &[href] {
    transition: color .25s; }
  &:hover {
    color: $green;
    @include t {
      color: $bl; }
    .submenu_head {
      .icon {
        fill: $green;
        @include dark {
          @include t {
            fill: $wh; } }
        @include t {
          fill: $bl-dark; } } } }
  @include nl {
    margin-bottom: 24px;
    @include x {
      margin-bottom: 20px; }
    @include t {
      margin-bottom: 24px; }
    @include m {
      margin: 0 24px 0 0; }
    @include a {
      margin: 0 0 16px; } } }

.submenu_head {
  display: flex;
  align-items: center;
  transition: color .25s;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  cursor: pointer;
  .icon {
    margin-left: 16px;
    font-size: 14px;
    transition: fill .25s;
    @include dark {
      fill: $wh; } } }

.submenu_body {
  position: absolute;
  top: 0;
  left: calc(100% + 70px);
  width: 0;
  overflow: hidden;
  transition: width .25s;
  @include dark {
    @include m {
      background: $bg-dark; } }
  @include rmin(768) {
    visibility: visible;
    opacity: 1; }
  @include m {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 56px 0 32px;
    overflow: visible;
    background: $wh;
    visibility: hidden;
    opacity: 0;
    transition: all .25s; }
  @include a {
    padding-top: 106px; } }

.active_link {
  font-weight: 500;
  color: $green;
  @include dark {
    color: $green; }
  &:hover {
    color: $bl;
    @include t {
      color: inherit; }
    @include dark {
      color: $wh; } } }

.active_link .submenu_head {
  color: $green;
  .icon {
    fill: $green;
    @include dark {
      fill: $green; }
    @include t {
      fill: $green; } } }

.active_link .submenu_body {
  width: 100%;
  @include m {
    visibility: visible;
    opacity: 1; } }

.button_back, .button_close {
  display: none;
  font-size: 0;
  @include dark {
    @include m {
      background: $dark;
      .icon {
        fill: $wh; } } }
  @include m {
    display: inline-block;
    position: fixed;
    top: 32px;
    z-index: 20;
    width: 32px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $gray;
    .icon {
      font-size: 16px;
      fill: $bl-dark; } } }

.button_back {
  left: 32px; }

.button_close {
  right: 32px; }

.submenu_group {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include m {
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;  // IE 10+
    scrollbar-width: none;  // Firefox
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none; } } }

.submenu_group .submenu_link {
  white-space: nowrap;
  font-weight: 400;
  color: $bl;
  transition: color .25s;
  @include dark {
    color: $dark-wh;
    &:hover {
      color: $green; } }
  &:hover {
    color: $green; }
  @include nl {
    margin-bottom: 24px;
    @include x {
      margin-bottom: 20px; }
    @include t {
      margin-bottom: 24px; }
    @include m {
      margin-bottom: 16px; } } }

.left {
  @include m {
    z-index: 10; } }

.left .menu_list {
  transform: translateX(-110px);
  @include m {
    transform: none; } }

.button {
  display: none;
  @include m {
    display: inline-flex;
    flex-shrink: 0;
    margin: auto 0 40px; } }












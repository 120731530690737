@import "../../../styles/helpers";

.about {
  text-align: center; }

.center {
  max-width: 1244px; }

.title {
  @include t {
    font-size: 32px;
    line-height: 1.5; } }

.list {
  display: flex;
  @include d {
    flex-wrap: wrap;
    justify-content: center;
    margin: -64px -32px 0; }
  @include t {
    display: block;
    margin: 0; } }

.item {
  flex-shrink: 0;
  width: 304px;
  @include d {
    flex: 0 0 calc(50% - 64px);
    width: calc(50% - 64px);
    margin: 64px 32px 0; }
  @include t {
    width: 100%;
    margin: 0; }
  @include nl {
    margin-right: auto;
    @include d {
      margin-right: 32px; }
    @include t {
      margin: 0 0 64px; }
    @include m {
      margin-bottom: 56px; } } }

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin: 0 auto 32px;
  border-radius: 50%;
  background: $gray;
  font-size: 0;
  path {
    stroke: $bl-dark; }
  @include dark {
    background: $dark;
    path {
      stroke: $wh; } } }

.category {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: (40/24);
  font-weight: 600; }

.text {
  font-size: 20px;
  line-height: (32/20); }
